<template>
  <div>
    <el-breadcrumb style="margin: 0 0 6px 6px" separator="/">
      <!-- <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>学校管理</el-breadcrumb-item>
      <el-breadcrumb-item>学校列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">入库管理</div>
      <div class="xian"></div>-->
      <div class="list_box">
        <div class="tt1">学校列表</div>
        <div>
          <el-button
            size="mini"
            type="cha"
            icon="el-icon-search"
            @click="chaxun"
            >查询</el-button
          >
          <el-button
            type="add"
            size="mini"
            icon="el-icon-circle-plus-outline"
            @click="add"
            >添加</el-button
          >
        </div>
      </div>
      <div class="int_box">
        <div class="int_box_a">
          <span>名称：</span>
          <el-input
            size="small"
            class="int_width"
            placeholder="请输入名称"
            v-model="name"
            clearable
          ></el-input>
        </div>
      </div>
      <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="indexList"
          style="width: 100%"
          stripe
        >
          <el-table-column prop="id" label="ID号" width="60"></el-table-column>
          <el-table-column prop="school_name" label="名称"></el-table-column>
          <el-table-column
            prop="school_sub_ip"
            label="子系统IP"
          ></el-table-column>
          <el-table-column
            prop="salt_key"
            label="子系统账户key"
          ></el-table-column>
          <el-table-column
            prop="salt_secret"
            label="子系统秘钥"
          ></el-table-column>
          <el-table-column prop="sub_host" label="子系统域名"></el-table-column>

          <el-table-column prop="add_date" label="创建时间"></el-table-column>
          <el-table-column width="70px" label="操作">
            <template v-slot="scope">
              <el-tooltip
                :enterable="false"
                effect="dark"
                content="编辑"
                placement="top-end"
              >
                <el-button
                  size="mini"
                  type="primary"
                  icon="iconfont icon-bianxie"
                  @click="edit(scope.row)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 15, 30, 50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <el-dialog
        :title="text"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="1000px"
        :before-close="handleClose"
      >
        <div class="xian2"></div>
        <div style="max-height: 500px; overflow-y: auto">
          <el-form
            ref="form"
            :model="addform"
            :rules="rules"
            label-width="140px"
          >
            <el-form-item label="学校名称：" prop="name">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.name"
                placeholder="请输入学校名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="地址：" prop="addr">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.addr"
                placeholder="请输入地址"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="子系统ip：" prop="sub_ip">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.sub_ip"
                placeholder="请输入子系统ip"
                clearable
              ></el-input>
            </el-form-item>

            <el-form-item label="子系统账户key：" prop="key">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.key"
                placeholder="请输入子系统账户key"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="子系统秘钥：" prop="secret">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.secret"
                placeholder="请输入子系统秘钥"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="子系统域名：">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.host"
                placeholder="请输入子系统域名"
                clearable
              ></el-input>
            </el-form-item>
            
            <el-form-item label="腾讯音频appid：" prop="tencent_appid">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.tencent_appid"
                placeholder="请输入腾讯音频appid"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="腾讯音频appkey：" prop="tencent_key">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.tencent_key"
                placeholder="请输入腾讯音频appkey"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="百度语音appid：" prop="baidu_app_id">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.baidu_app_id"
                placeholder="请输入百度语音appid"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="百度语音apikey：" prop="baidu_api_key">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.baidu_api_key"
                placeholder="请输入百度语音apikey"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="百度语音secretkey：" prop="baidu_secret_key">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.baidu_secret_key"
                placeholder="请输入百度语音secretkey"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="点位数：" prop="student_num">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.student_num"
                placeholder="请输入点位数"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="购买日期：">
              <el-date-picker
                size="small"
                style="width: 450px"
                value-format="yyyy-MM-dd"
                v-model="addform.buy_date"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="是否锁定：" v-if="id">
              <el-radio-group v-model="addform.locked">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否删除：" v-if="id">
              <el-radio-group v-model="addform.delete">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <el-button v-if="id" type="cha" class="add_btn" @click="edit_btn"
          >确定</el-button
        >
        <el-button v-else type="cha" class="add_btn" @click="add_btn"
          >确定</el-button
        >
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      limit: 10,
      name: '',
      indexList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },

      dialogVisible: false,
      addform: {
        name: '',
        addr: '',
        sub_ip: '',
        key: '',
        secret: '',
        host: '',
        buy_date: '',
        tencent_appid: '',
        tencent_key: '',
        baidu_app_id: '',
        baidu_api_key: '',
        baidu_secret_key: '',
        student_num: '',
        locked: 0,
        delete: 0,
        no: '',
      },

      rules: {
        name: [{ required: true, message: '请输入学校名称', trigger: 'blur' }],
        addr: [{ required: true, message: '请输入地址', trigger: 'blur' }],

        sub_ip: [
          { required: true, message: '请输入子系统ip', trigger: 'blur' },
        ],
        key: [
          { required: true, message: '请输入子系统账户key', trigger: 'blur' },
        ],
        secret: [
          { required: true, message: '请输入子系统秘钥', trigger: 'blur' },
        ],
        tencent_appid: [
          { required: true, message: '请输入腾讯音频appid', trigger: 'blur' },
        ],
        tencent_key: [
          { required: true, message: '请输入腾讯音频appkey', trigger: 'blur' },
        ],
        baidu_app_id: [
          { required: true, message: '请输入百度语音appid', trigger: 'blur' },
        ],
        baidu_api_key: [
          { required: true, message: '请输入百度语音apikey', trigger: 'blur' },
        ],
        baidu_secret_key: [
          { required: true, message: '请输入百度语音secretkey', trigger: 'blur' },
        ],
        student_num: [
          { required: true, message: '请输入点位数', trigger: 'blur' },
        ],
      },
      id: '',
      text: '',
      URL: '',
      URL_img: '',
    }
  },
  mounted() {
    this.index()
    this.URL = this.$URL
    this.URL_img = this.$URL_img
  },
  methods: {
    handleSizeChange(val) {
      this.limit = val
      this.index()
    },
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    handleClose() {
      this.id = ''
      this.dialogVisible = false
      this.$refs.form.resetFields()
      this.addform = {
        name: '',
        addr: '',
        sub_ip: '',
        key: '',
        secret: '',
        host: '',
        buy_date: '',
        tencent_appid: '',
        tencent_key: '',
        baidu_app_id: '',
        baidu_api_key: '',
        baidu_secret_key: '',
        student_num: '',
        locked: 0,
        delete: 0,
        no: '',
      }
    },
    // __token__令牌
    async __token__() {
      const { data: res } = await this.$http.get(
        'index.php/api/index/formtoken'
      )
      if (res.code !== 1) return this.$message.error(res.msg)
      this.token = res.data.info
    },
    // 列表
    async index() {
      const { data: res } = await this.$http.get('edulogin.php/school/index', {
        params: {
          page: this.page,
          limit: this.limit,
          name: this.name,
        },
      })
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
      this.total = res.data.total
    },

    // 查询
    chaxun() {
      this.page = 1
      this.index()
    },
    // 点击添加按钮，弹出添加弹出框
    add() {
      this.text = '添加'
      this.dialogVisible = true
      this.__token__()
    },

    // 添加确认
    add_btn() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')
        this.addform.__token__ = this.token
        const { data: res } = await this.$http.post(
          'edulogin.php/school/add',
          this.addform
        )
        if (res.code != 1) {
          this.token = res.data.__token__
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.dialogVisible = false
        this.$refs.form.resetFields()
        ;(this.addform = {
          name: '',
          addr: '',
          sub_ip: '',
          key: '',
          secret: '',
          host: '',
          buy_date: '',
          tencent_appid: '',
          tencent_key: '',
          baidu_app_id: '',
          baidu_api_key: '',
          baidu_secret_key: '',
          student_num: '',
          locked: 0,
          delete: 0,
          no: '',
        }),
          this.index()
      })
    },
    // 点击编辑
    edit(row) {
      this.text = '编辑'
      this.id = row.id
      this.dialogVisible = true
      this.addform = {
        name: row.school_name,
        addr: row.school_addr,
        sub_ip: row.school_sub_ip,
        key: row.salt_key,
        secret: row.salt_secret,
        host: row.sub_host,
        buy_date: row.buy_date,
        tencent_appid: row.tencent_appid,
        tencent_key: row.tencent_key,
        baidu_app_id: row.baidu_app_id,
        baidu_api_key: row.baidu_api_key,
        baidu_secret_key: row.baidu_secret_key,
        student_num: row.student_num,
        locked: row.locked,
        delete: row.is_delete,
        no: row.school_no,
      }
      this.__token__()
    },
    // 编辑确认
    async edit_btn() {
      this.addform.__token__ = this.token
      const { data: res } = await this.$http.post(
        'edulogin.php/school/edit',
        this.addform
      )
      if (res.code !== 1) {
        this.token = res.data.__token__
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.$refs.form.resetFields()
      this.dialogVisible = false
      ;(this.addform = {
        name: '',
        addr: '',
        sub_ip: '',
        key: '',
        secret: '',
        host: '',
        buy_date: '',
        tencent_appid: '',
        tencent_key: '',
        baidu_app_id: '',
        baidu_api_key: '',
        baidu_secret_key: '',
        student_num: '',
        locked: 0,
        delete: 0,
        no: '',
      }),
        (this.id = '')
      this.index()
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 38px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box1 {
    padding: 0 1px 15px 1px;
  }
  .int_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px 1px 0px;
  }
  .int_box_a {
    padding-bottom: 15px;
  }
  .int_width {
    width: 242px;
    margin-right: 40px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .ttright {
    font-size: 14px;
    margin-left: 20px;
    color: #666;
  }
  .add_btn {
    width: 140px;
    margin: 40px 0 30px 420px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9 !important;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .list_img {
    width: 40px;
    height: 40px;
    display: block;
  }
}
</style>
